import MuiDarkModeIcon from "@mui/icons-material/DarkMode";
import MuiLightModeIcon from "@mui/icons-material/LightMode";
import MuiLoginIcon from "@mui/icons-material/Login";
import MuiLogoutIcon from "@mui/icons-material/Logout";
import MuiLaunchIcon from "@mui/icons-material/Launch";
import MuiAccountCircleIcon from "@mui/icons-material/AccountCircle";
import MuiHomeIcon from "@mui/icons-material/Home";
import MuiContentCopyIcon from "@mui/icons-material/ContentCopy";
import MuiDownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import MuiUploadIcon from "@mui/icons-material/Upload";
import MuiHourglassFullIcon from "@mui/icons-material/HourglassFull";
import MuiRefreshIcon from '@mui/icons-material/Refresh';

export const LightModeIcon = MuiLightModeIcon;
export const DarkModeIcon = MuiDarkModeIcon;
export const SignInIcon = MuiLoginIcon;
export const SignOutIcon = MuiLogoutIcon;
export const LaunchIcon = MuiLaunchIcon;
export const AccountIcon = MuiAccountCircleIcon;
export const HomeIcon = MuiHomeIcon;
export const CopyIcon = MuiContentCopyIcon;
export const DownloadIcon = MuiDownloadForOfflineIcon;
export const UploadIcon = MuiUploadIcon;
export const PendingIcon = MuiHourglassFullIcon;
export const RefreshIcon = MuiRefreshIcon;
